import * as axios from 'axios'
import { GetEnv } from '../../shared/get-env.util';
import { BriefInfoApi } from "../interfaces/brief-info-api.interface";

export async function GetBriefInfo(otp: string): Promise<BriefInfoApi> {
  let env = GetEnv(process.env.REACT_APP_ENV || 'dev')
  let url = `${env.apiBaseUrl}/submit-answers/get-brief-info/${otp}`
  try {
    let resp = await axios.default.get(url)
    return Promise.resolve(JSON.parse(JSON.stringify(resp.data)))
  } catch(err) {
    console.log(`error getting brief info: ${JSON.stringify(err)}`)
    return Promise.reject(JSON.stringify(err))
  }
  

}