export interface AppEnv {
  envName: string
  apiBaseUrl: string
}

export function GetEnv(envName: string): AppEnv {
  let ret: AppEnv = {
    envName: 'dev',
    apiBaseUrl: `https://0883a6mov1.execute-api.us-east-1.amazonaws.com/dev`
  }

  if (envName === 'test') {
    ret.envName = 'test'
    ret.apiBaseUrl = 'https://75droeq4jh.execute-api.us-east-1.amazonaws.com/test'
  }

  if (envName === 'prod') {
    ret.envName = 'prod'
    ret.apiBaseUrl = 'https://duwrsio5w5.execute-api.us-east-1.amazonaws.com/prod'
  }

  return ret
}