import React, { useEffect, useRef, useState } from 'react';
import useInterval from '../custom-hooks/use-interval.hook';
import { useParams } from "react-router-dom";
import * as axios from 'axios'
import { BriefContactDynamo } from './interfaces/brief-contact-dynamo.interface';
import { AuthenticateViaOtp } from './utils/authenticate-via-otp.util';
import { MockGetQuestionsForBrief } from './utils/mock-get-questions-for-brief.util';
import { BriefQuestionAnswer, BriefQuestionDynamo } from './interfaces/brief-question-dynamo.interface';
import {Button, ButtonGroup, Card, Col, Container, FloatingLabel, Form, Row, Spinner, Toast, ToastContainer } from 'react-bootstrap';

import { SubmitAnswersService } from './submit-answers.service.export';
import { BriefAnswer, BriefAnswersDynamo } from './interfaces/brief-answers-dynamo.interface';
import QuestionComponent from './question-card.component';
import { stringify } from 'querystring';
import { GetBriefInfo } from './utils/get-brief-info.util';
import { BriefInfoApi } from './interfaces/brief-info-api.interface';
import { GetEnv } from '../shared/get-env.util';
import {Question} from "../../../src/avail/views/questions/question.interface";
import {hasQuestionBeenAnswered} from "./utils/has-question-been-answered.util";

export default function SubmitAnswers() {
  // setup state
  const [briefAnswers, setBriefAnswers] = React.useState<BriefAnswersDynamo | undefined>(undefined)
  const [briefContact, setBriefContact] = React.useState<BriefContactDynamo | undefined>(undefined)
  const [briefInfo, setBriefInfo] = React.useState<BriefInfoApi | undefined>(undefined)
  const [briefInfoForStats, setBriefInfoForStats] = React.useState<BriefInfoApi | undefined>(undefined)
  const [briefQuestions, setBriefQuestions] = React.useState<BriefQuestionDynamo[] | undefined>(undefined)
  const briefQuestionsRef = useRef(briefQuestions);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [hasAttemptedToAuthenticate, toggleHasAttemptedToAuthenticate] = React.useState(false);
  const [showLoading, toggleShowLoading] = React.useState(true);
  const [userIsAuthenticated, toggleUserIsAuthenticated] = React.useState(false);
  const [updatedAnswers, setUpdatedAnswers] = React.useState<BriefAnswersDynamo | undefined>(undefined)
  const [viewAnswerToggle, setViewAnswerToggle] = React.useState('unanswered')

  // get url params
  let params = useParams();

  // attempt to authenticate via josh api
  let env = GetEnv(process.env.REACT_APP_ENV || 'dev')
  let url = `${env.apiBaseUrl}/uuid/${params.otp}/`
  useEffect(() => {
    async function attemptAuth() {
      
      await toggleShowLoading(true)
      // try {
      //   let axiosResp = await axios.default.get(url)
      //   if ((axiosResp.status >= 200) && (axiosResp.status <= 299)) {
      //     console.log(`axiosResp: `)
      //     console.log(axiosResp)
      //   } else {
      //     console.log(`did not get a 200 response`)
      //   }
      // } catch(err) {
      //   console.log(`error trying to authenticate`)
      //   console.log(err)
      // }
      try {
        // try main authentication
        console.log(`trying AuthenticateViaOtp...`)
        let briefContact = await AuthenticateViaOtp(params.otp || 'UNKNOWN')
        await toggleUserIsAuthenticated(true)
        await setBriefContact(briefContact)

        console.log(`trying getBriefInfo...`)
        // get brief info
        let briefInfo = await getBriefInfo(params.otp || 'UNKNOWN')

        // set briefInfoForStats
        await setBriefInfoForStats(briefInfo)

        // set questions
        let sortedQuestions = briefInfo.questions.sort((a: BriefQuestionDynamo,b: BriefQuestionDynamo) => {
          if (a.question.question.avail_id < b.question.question.avail_id) {
            return -1
          } else {
            return 1
          }
        })
        await setBriefQuestions(sortedQuestions)

        // set answers
        await setBriefAnswers(briefInfo.answers)

        // set updatedAnswers
        await setUpdatedAnswers(briefInfo.answers)
      } catch(err: any) {
        console.log(`error AuthenticateViaOtp: `)
        console.log(err)
        if (err.includes('404')) {
          await setErrorMessage("One Time Password / Token not found")
        }
        if (err.includes('401')) {
          await setErrorMessage("One Time Password / Token has expired")
        }
      }
      await toggleHasAttemptedToAuthenticate(true)
      // console.log(`briefContact: `)
      // console.log(briefContact)

      

      
      
      await toggleShowLoading(false)
    }

    if (hasAttemptedToAuthenticate === false) {
      attemptAuth()
    }
    
  }, [])

  useInterval( async () => {
    if (userIsAuthenticated === true) {
      let updatedBriefInfo = await GetBriefInfo(params.otp || 'UNKNOWN')
      await setUpdatedAnswers(updatedBriefInfo.answers)
      console.log(`parent setUpdatedAnswers...`)
    }
  }, 5000)

  let getBriefInfo = async (otp: string): Promise<BriefInfoApi> => {
    let briefInfo = await GetBriefInfo(otp)
    console.log(`briefInfo: `)
    console.log(briefInfo)
    await setBriefInfo(briefInfo)
    return Promise.resolve(briefInfo)
  }

  let updateBriefInfoForStats = async (): Promise<void> => {
    let briefInfo = await GetBriefInfo(params.otp || 'UNKNOWN')
    // console.log(`briefInfo: `)
    // console.log(briefInfo)
    await setBriefInfoForStats(briefInfo)
    // return Promise.resolve(briefInfo)
  }


  

  return (
    <div style={{marginLeft: "5%", marginRight: "5%", marginTop: "5%"}}>
      {(userIsAuthenticated === false) && (hasAttemptedToAuthenticate === true) &&
        <span>
          You are not authenticated<br />{' '}<br />
          {errorMessage}
          {/* breifId: {params.briefId}<br />
          otp: {params.otp}<br /> */}
        </span>
      }
      {userIsAuthenticated === true &&
        <Row>
          <Col xs={12} sm={6}>
            <h5>Answering Questions for Brief: {briefInfo?.metadata.name}</h5>
            <h6>
              Answering questions as {briefContact?.contact.contact['email']} ({briefContact?.contact.contact['vendorName']})
            </h6>
            {briefInfoForStats &&
              <ButtonGroup aria-label="Basic example">
                  <Button
                      variant={viewAnswerToggle === 'unanswered' ? "secondary" : "outline-secondary"}
                      onClick={() => setViewAnswerToggle('unanswered')}
                  >
                      Unanswered ({briefInfoForStats.questions.length - briefInfoForStats.answers.answers.length})
                  </Button>
                  <Button
                      variant={viewAnswerToggle === 'answered' ? "secondary" : "outline-secondary"}
                      onClick={() => setViewAnswerToggle('answered')}
                  >Answered ({briefInfoForStats.answers.answers.length})</Button>
                  <Button
                      variant={viewAnswerToggle === 'all' ? "secondary" : "outline-secondary"}
                      onClick={() => setViewAnswerToggle('all')}
                  >
                      All ({briefInfoForStats.questions.length})
                  </Button>
              </ButtonGroup>
          }
          </Col>
            <Col xs={12} sm={1}>
                &nbsp;
            </Col>
          <Col xs={12} sm={5}>
              <p className={'font-weight-light'}>
                  Please contact scott.maurice@availpartners.net if you have any questions or issues while submitting your
                  answers.
              </p>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
            {briefInfoForStats && (briefInfoForStats.questions.length - briefInfoForStats.answers.answers.length) === 0 &&
                <>
                <Row>
                    <Col xs={12}>
                      <h3>You Rock! All questions have been answered and sent to Avail Partners!</h3>
                    </Col>
                </Row>
                <hr />
                </>
            }

          {briefQuestions === undefined &&
            <Card>
              <Card.Body>
                <Card.Title>Loading Questions...</Card.Title>
                <Card.Text>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Card.Text>
              </Card.Body>
            </Card>
          }
          
              
          {briefQuestions !== undefined && briefAnswers !== undefined && briefContact !== undefined && updatedAnswers !== undefined &&
            <Container>

              <Row>
                {briefQuestions.map((question: BriefQuestionDynamo, index: number) => {
                  // let showItem = false
                  // if (viewAnswerToggle === 'all') {
                  //   showItem = true
                  // }
                  // let answered = hasQuestionBeenAnswered({question: question, answersDynamo: briefAnswers})
                  // if (answered) {
                  //   if (viewAnswerToggle === 'answered') {
                  //     showItem = true
                  //   }
                  // } else {
                  //   if (viewAnswerToggle === 'unanswered') {
                  //     showItem = true
                  //   }
                  // }
                  // TODO figure out how to determine if question is answered or not...
                  return <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3} key={question.item_type_id}>
                    <QuestionComponent
                      question={question}
                      answersDynamo={briefAnswers}
                      contact={briefContact}
                      otp={params.otp || 'UNKNOWN'}
                      updateParentStats={updateBriefInfoForStats}
                      updatedAnswersFromParent={updatedAnswers}
                      answerToggle={viewAnswerToggle}
                    />
                  </Col>
                })}
              </Row>
              <Row>
                <Col>
                  {/* SPACER */}
                  &nbsp;<br />
                  &nbsp;<br />
                  &nbsp;<br />
                  &nbsp;<br />
                  &nbsp;<br />
                  &nbsp;<br />
                  &nbsp;<br />
                  &nbsp;<br />
                  &nbsp;<br />
                </Col>
              </Row>
            </Container>
            
          }

        </Row>
      }
      {briefInfoForStats !== undefined &&
        <Container>
          <Row>
            <Col>
              <ToastContainer className="position-fixed" position={'bottom-start'} >
                <Toast>
                  <Toast.Header closeButton={false}>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">
                      Answered {briefInfoForStats.answers.answers.length} of {briefInfoForStats.questions.length}
                    </strong>
                    <small>{(briefInfoForStats.answers.answers.length/briefInfoForStats.questions.length)*100}%</small>
                  </Toast.Header>
                  {/* <Toast.Body>Hello, world! This is a toast message.</Toast.Body> */}
                </Toast>
              </ToastContainer>
            </Col>
          </Row>
        </Container>
      }
      {hasAttemptedToAuthenticate === false &&
        <span>
          <h2>Authenticating...</h2>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </span>
      }
    </div>
  );
}