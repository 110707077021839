import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SubmitAnswers from './submit-answers/submit-answers';
import FourZeroFour from './404/404-page';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './navbar/navbar';

ReactDOM.render(
  <span>
    <NavbarComponent />
    <div className="content-margin-2">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="submit-answers" element={<SubmitAnswers />}>
              <Route path=":briefId" element={<FourZeroFour />}>
                <Route path=":otp" element={<SubmitAnswers />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  </span>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
