import React, { useEffect} from 'react';
import { Card,  Form, Spinner } from "react-bootstrap";
import { BriefAnswer, BriefAnswersDynamo } from "./interfaces/brief-answers-dynamo.interface";
import { BriefQuestionAnswer, BriefQuestionDynamo } from "./interfaces/brief-question-dynamo.interface";
import { CheckLg, XLg } from 'react-bootstrap-icons';
import { BriefContactDynamo } from './interfaces/brief-contact-dynamo.interface';
import { AnswerQuestion } from './utils/answer-question.util';

interface IQuestionProps {
  question: BriefQuestionDynamo
  answersDynamo: BriefAnswersDynamo
  contact: BriefContactDynamo
  otp: string
  updateParentStats(): any 
  updatedAnswersFromParent: BriefAnswersDynamo
  answerToggle: string
}

export interface QuestionComponentInput {
  question: BriefQuestionDynamo
  answersDynamo: BriefAnswersDynamo
  briefId: string
  otp: string

}

const QuestionComponent: React.FC<IQuestionProps> = ({question, answersDynamo, contact, otp, updateParentStats, updatedAnswersFromParent, answerToggle}) => {
  const [showLoading, setShowLoading] = React.useState(false)
  const [answer, setAnswer] = React.useState<BriefAnswer | undefined>(undefined)
  const [showCard, setShowCard] = React.useState(false)

  // use this to setup some stuff
  useEffect(() => {
    async function setAnswerToUse(): Promise<void> {
      // get / set answer
      let answerToUse: BriefAnswer | undefined = undefined
      if (answer === undefined) {
        for (let i of answersDynamo.answers) {
          if (i.question_item_type_id === question.item_type_id) {
            answerToUse = i
            break
          }
        }
        console.log(`setAnswer via the useEffect => setAnswerToUse function(s)...`)
        await setAnswer(answerToUse)
      }
    }
    setAnswerToUse()

    // when parent sends updatedAnswersFromParent, lets compare and see what we get
    let updatedAnswerToUse = getOurAnswerFromUpdatedAnswersFromParent(question.item_type_id, updatedAnswersFromParent)
    // console.log(`updatedAnswerToUse`)
    // console.log(updatedAnswerToUse)
    // determine if we need to update our answer with the newest on from parent
    async function updateAnswer(): Promise<void> {
      if (updatedAnswerToUse !== undefined) {
        if (answer === undefined) {
          await setAnswer(updatedAnswerToUse)
        } else {
          if (updatedAnswerToUse.answerTimestamp > answer.answerTimestamp) {
            await setAnswer(updatedAnswerToUse)
          }
        }
      }
    }
    updateAnswer()
    


    // console.log(`contact: `)
    // console.log(contact)
  })

  // determine if we show card or not
  useEffect(() => {
    const asyncFunc = async () => {
      console.log(`answerToggle: ${answerToggle}`)
      console.log(`answer: ${JSON.stringify(answer)}`)
      // await setShowCard(false)
      if (answerToggle === 'all') {
        await setShowCard(true)
      }
      if (answerToggle === 'answered') {
        if (answer !== undefined) {
          await setShowCard(true)
        }
        if (answer === undefined) {
          await setShowCard(false)
        }
      }
      if (answerToggle === 'unanswered') {
        if (answer === undefined) {
          await setShowCard(true)
        } else {
          await setShowCard(false)
        }
      }
    }
    asyncFunc().then(() => {})
  })

  let getScoreForAnswer = (answerStr: string): number => {
    let ret = -99
    for (let i of question.question.question.answers) {
      if (i.answer === answerStr) {
        ret = i.score
      }
    }
    return ret
  }

  let handleAnswerChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    await setShowLoading(true)
    let answerToSend: BriefAnswer | undefined
    let rightNowIso = new Date().toISOString()
    let contactEmail = contact.contact.contact['email']
    // Create or update current answer
    if (answer === undefined) {
      answerToSend = {
        question: question.question.question.question,
        question_item_type_id: question.item_type_id,
        answer: event.target.value,
        score: getScoreForAnswer(event.target.value),
        contactEmail: contactEmail,
        answerTimestamp: rightNowIso,
        answerHistory: [
          {
            answer: event.target.value,
            contactEmail: contactEmail,
            answerTimestamp: rightNowIso,
          }
        ]
      } 
    } else {
      answerToSend = JSON.parse(JSON.stringify(answer))
      if (answerToSend !== undefined) {
        answerToSend.answer = event.target.value
        answerToSend.score = getScoreForAnswer(event.target.value)
        answerToSend.contactEmail = contactEmail
        answerToSend.answerTimestamp = rightNowIso
        answerToSend.answerHistory.push({
          answer: event.target.value,
          contactEmail: contactEmail,
          answerTimestamp: rightNowIso,
        })
      }
    }
    // send to Josh API
    if (answerToSend !== undefined) {
      try {
        let answerQuestResp = await AnswerQuestion(answerToSend, otp)
        console.log(`answerQuestResp: `)
        console.log(answerQuestResp)
        await setAnswer(answerToSend)
        console.log(`setAnswer via the handle change function...`)
      } catch(err) {
        console.log(`error answering question: ${JSON.stringify(err)}`)
      }
      // let resp = await AnswerQuestion(answerToSend)
    }
    console.log(`event: `)
    console.log(event)
    console.log(`we changed answer...`)
    updateParentStats() // send signal to parent to update stats
    await setShowLoading(false)
  }

  let getOurAnswerFromUpdatedAnswersFromParent = (questionItemTypeId: string, updatedAnswers: BriefAnswersDynamo): BriefAnswer | undefined => {
    let ret = undefined
    for (let i of updatedAnswers.answers) {
      if (i.question_item_type_id === questionItemTypeId) {
        ret = i
        break
      }
    }
    return ret
  }

  if (!showCard) {
    return <></>
  }
  return (
    <span>
    <Card border={answer === undefined ? "danger" : "success"}>
      <Card.Body>
        <Card.Text>
          
                <Form.Group className="mb-3" controlId={question.item_type_id}>
                  <Form.Label>
                    {showLoading &&
                      <span>
                        <Spinner animation="border" role="status" variant="secondary" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        &nbsp;&nbsp;
                      </span>
                    }
                    {question.question.question.avail_id} - {question.question.question.question}
                  </Form.Label>
                  <Form.Select aria-label="Floating label select example" onChange={handleAnswerChange}
                    disabled={showLoading}
                  >
                    {answer === undefined &&
                      <option>Please select an answer</option>
                    }
                    {question.question.question.answers.map((item: BriefQuestionAnswer, index: number) => {
                      return <option key={index} value={item.answer} selected={answer?.answer === item.answer}>{item.answer}</option>
                    })}
                  </Form.Select>
                </Form.Group>
                {answer === undefined &&
                  // <Alert key={0} variant={"danger"}>
                  //   <XLg color="red" />&nbsp;required *
                  // </Alert>
                  <small><XLg color="red" />&nbsp;required *</small>
                } 
                {answer !== undefined &&
                  // <Alert key={0} variant={"success"}>
                  //   <CheckLg color="green" />&nbsp;Answered by {answer.contactEmail} at {answer.answerTimestamp}
                  // </Alert>
                  <small><CheckLg color="green" />&nbsp;Answered by {answer.contactEmail} at {answer.answerTimestamp}</small>
                }
        </Card.Text>
      </Card.Body>
    </Card>
    {' '}<br />{' '}
    </span>
  )
}

export default QuestionComponent

// export default function QuestionComponent({question}) {
//   return (
//     <Card>
//       <Card.Body>
//         <Card.Text>
//           <Form.Group className="mb-3" controlId={question.item_type_id}>
//             <Form.Label>{question.question.question}</Form.Label>
//             <Form.Select aria-label="Floating label select example">
//               {question.question.question.answers.map((answer: BriefQuestionAnswer, index: number) => {
//                 return <option key={index}>{answer.answer}</option>
//               })}
//             </Form.Select>
//           </Form.Group>
//         </Card.Text>
//       </Card.Body>
//     </Card>
//   )
// }