import * as axios from 'axios'
import { GetEnv } from '../../shared/get-env.util'

import { BriefContactDynamo } from "../interfaces/brief-contact-dynamo.interface"

export async function AuthenticateViaOtp(otp: string): Promise<BriefContactDynamo> {
  let env = GetEnv(process.env.REACT_APP_ENV || 'dev')
  let url = `${env.apiBaseUrl}/uuid/${otp}`
  try {
    let resp = await axios.default.post(url)
    return Promise.resolve(JSON.parse(JSON.stringify(resp.data)))
  } catch(err) {
    console.log(`error authenticating via otp: ${JSON.stringify(err)}`)
    return Promise.reject(JSON.stringify(err))
  }
}