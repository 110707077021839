import * as axios from 'axios'
import { GetEnv } from '../../shared/get-env.util';
import { BriefAnswer } from "../interfaces/brief-answers-dynamo.interface";

export interface AnswerQuestionOutput {
  wasSuccessful: boolean
}

export async function AnswerQuestion(answer: BriefAnswer, otp: string): Promise<AnswerQuestionOutput> {
  let env = GetEnv(process.env.REACT_APP_ENV || 'dev')
  let url = `${env.apiBaseUrl}/submit-answers/${otp}/${answer.question_item_type_id}`
  try {
    let resp = await axios.default.put(url, answer)
    return Promise.resolve({wasSuccessful: true})
  } catch(err) {
    console.log(`error authenticating via otp: ${JSON.stringify(err)}`)
    return Promise.reject(JSON.stringify(err))
  }
}