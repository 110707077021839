import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom';
import SubmitAnswers from './submit-answers/submit-answers';
import Navbar from './navbar/navbar';

function App() {
  return (
    <div>
      <Outlet />
    </div>
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<SubmitAnswers />}>
    //       <Route index element={<SubmitAnswers />} />
    //       <Route path="submit-answers" element={<SubmitAnswers />}>
    //         {/* <Route path=":teamId" element={<Team />} />
    //         <Route path="new" element={<NewTeamForm />} />
    //         <Route index element={<LeagueStandings />} /> */}
    //       </Route>
    //     </Route>
    //   </Routes>
    // </BrowserRouter>
  );
}

export default App;
